import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "customers"
    }}>{`Customers`}</h1>
    <p>{`The Customers API provides functionality for managing profiles, consents and benefits for your organisation's customers.
The features include among others: `}</p>
    <ul>
      <li parentName="ul">{`managing profiles`}</li>
      <li parentName="ul">{`profile authentication (verify username and password)`}</li>
      <li parentName="ul">{`managing temporary profiles used for anonymous sales `}</li>
      <li parentName="ul">{`managing customer consents `}</li>
      <li parentName="ul">{`fetching traveller info for a specific journey`}</li>
      <li parentName="ul">{`managing loyalty programs `}</li>
      <li parentName="ul">{`fetching customer entitlements used for sales discounts`}</li>
    </ul>
    <h2 {...{
      "id": "services"
    }}>{`Services`}</h2>
    <p>{`Profiles, Benefits and Consents are the services provided by the Customers API.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Service`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/pages-profiles-docs-profiles"
            }}>{`Profiles`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Manage customer profiles and authentication`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/pages-benefits-docs-benefits-api"
            }}>{`Benefits`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Manage benefits for customers`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/pages-consents-docs-consents"
            }}>{`Consents`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Manage consents between your organisation and customers`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "usage-in-sales"
    }}>{`Usage in sales`}</h2>
    <h3 {...{
      "id": "profiles"
    }}>{`Profiles`}</h3>
    <p>{`Purchases made using the Entur sales platform may specify customer profiles for contact info and as reference for travellers.
This can be a permanent profile, or a temporary profile with optional information provided by the customer.`}</p>
    <h3 {...{
      "id": "benefits"
    }}>{`Benefits`}</h3>
    <p>{`Benefits can be used as a standalone API for your organisation to manage benefits for your customers,
or coupled with an Entitlement Product to give discounts for sales for customers with benefits.
This requires configuration of the Entitlement Product for the discount logic.
An example of this is Personnel tickets.`}</p>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`All endpoints in the Customers API requires an authentication header. The authentication header must be an Entur-issued OAuth2 bearer token (more details `}<a parentName="p" {...{
        "href": "https://developer.entur.org/pages-intro-authentication"
      }}>{`here`}</a>{`). If you have access to a client Id and secret, you can use the curl example to retrieve a token:`}</p>
    <pre><code parentName="pre" {...{}}>{`curl --request POST \\
  --url 'https://partner.entur.org/oauth/token' \\
  --header 'content-type: application/json' \\
  --data '{"grant_type":"client_credentials","client_id": "<clientid>","client_secret": "<clientsecret>","audience": "https://api.staging.entur.io"}'
`}</code></pre>
    <h3 {...{
      "id": "verify-token"
    }}>{`Verify token`}</h3>
    <p>{`To verify the authentication token you can try to find a customer profile with email:`}</p>
    <pre><code parentName="pre" {...{}}>{`curl -X GET "https://api.entur.io/customers/v2/profiles?email=<email> -H "accept: application/json;charset=UTF-8" -H "Authorization: Bearer <token>"
`}</code></pre>
    <p>{`If you are able to find the customer you are looking for, you are ready to go.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      